import React from 'react';
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import style from "./SocialMedia.module.css";
import {Link} from 'react-router-dom';


/*<Link to={{pathname:'https://github.com/weiyenchang'}} target="_blank" >

<AiFillGithub size="2em"/>

</Link>*/

const SocialMedia=()=>{
    return(
        <div className={style.socialmeida}>

            <Link to={{pathname:'https://www.linkedin.com/in/weiyenchang/'}} target="_blank" >

                <AiFillLinkedin size="2em" />

            </Link>

            <Link to={{pathname:'https://www.instagram.com/wyc.element/'}} target="_blank" >

                <AiOutlineInstagram size="2em"/>
                
            </Link>

        </div>
    )
}

export default SocialMedia;