import React,{Component} from 'react';
import Portfolio from '../Data/Portfolio.json';
import {motion} from 'framer-motion';
import {fade} from '../Animation/animation'
import style from './WorkPage.module.css';
import { FiExternalLink } from "react-icons/fi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import {Link} from "react-router-dom";
import WorkPageMenu from './WorkPageMenu';
import Footer from '../Footer/Footer';
class WorkPage extends Component{
    
    state={
        Portfolio,
    }

    render(){
        //從Link傳下來的Props
        const pageId=this.props.match.params.id;
        console.log(this.props.match.params);

        //用filter() 找出Works.json 中match pageId對應的作品
        const selectedWork= Portfolio.filter(
            Portfolio=>Portfolio.id===pageId
        )

        return(
            <motion.div 
                initial="out"
                animate="in"
                exit="out"
                variants={fade}
            >
                {selectedWork.map(
                    (selectedWork)=>(
                        <div key={selectedWork.id} className={style.work}>

                            <Link to="/">
                                <div className={style.back}>
                                <IoArrowBackCircleOutline  size="2.1em"/>
                                </div>
                            </Link>
                            
                            <div className={style.work_header}>
                                <h1>{selectedWork.entitle}</h1>
                                <h2>{selectedWork.zhtitle}</h2>
                                <div className={style.work_abbr}>
                                    <h3 style={selectedWork.lag!==""?{display:"block"}:{display:"none"}}>{selectedWork.lag}</h3>
                                    <a href={selectedWork.link} target="_blank" rel="noreferrer" style={selectedWork.link!==""?{display:"flex"}:{display:"none"}}>
                                        <FiExternalLink size="1.7em"/>
                                    </a>
                                </div>
                            </div>
                            
                            {
                                selectedWork.detail.map(
                                    detail=>(
                                            <div  key={detail.id} className={style.work_content}>
                                                <div className={style.work_img}>
                                                    <iframe title={detail.youtube} src={detail.youtube}  
                                                            style={detail.youtube!==""?{display:"block"}:{display:"none"}} >
                                                    </iframe>
                                                    <img src={`/img/${detail.image}`} alt={detail.image}
                                                        style={detail.image!==""?{display:"block",width:"100%"}:{display:"none"}}/>
                                                </div>
                                                <div className={style.work_text}>
                                                    <h3>{detail.title}</h3>
                                                    <p>{detail.endesc}</p>
                                                    <p>{detail.zhdesc}</p>
                                                </div>

                                            </div>
                                    )
                                )
                            }
                            <div  className={style.wp_menu} >
                                {Portfolio.map(
                                    (Portfolio,index)=>
                                    <div key={index}>
                                    <WorkPageMenu {...Portfolio}/>
                                    </div>
                                )}
                            </div>
                        </div>  
                    )
                )}
                <Footer/>
            </motion.div>
        )
    }
}

export default WorkPage;