import React from 'react';
import logo from './logo.svg';
import style from './Navbar.module.css';
import {Link} from 'react-router-dom';



const Navbar=()=>{
        return(
            <nav className={style.nav_web}>
                
                    <div className={style.brand_web} >
                        <Link to="/">
                        <img src={logo} alt="logo"/>
                        </Link>
                    </div>
                
            </nav>
        )
}
export default Navbar;