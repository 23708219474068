import styled from 'styled-components';

export default styled.button`
    text-decoration: none;
    font-size:1.2em;
    font-weight: 600;
    border: none;
    color:#F7F7F7;
    background-color: #033359;
    border-radius:30px;
    padding:5px;
    margin-top:30px;
    cursor: pointer;
    outline:0;
    width:120px;
    letter-spacing:0.5px;
    z-index:2;
    position: relative;
    overflow: hidden;
    
    ::after{
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: #033359;
        z-index: -2;
    }
    ::before{
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width: 0%;
        height: 100%;
        background-color:#e73c2b;
        transition: all .3s;
        z-index: -1;
    }
    :hover{
        color:#F7F7F7;
        ::before{
            width:100%;
        }
    }
    @media (max-width: 768px){ 
        margin-left:20px;
        font-size:1em;
        width:180px;
        margin-top:15px;
    }
`
