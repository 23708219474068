import React,{Component} from 'react';
import style from './Footer.module.css';

class Footer extends Component{
    
    state={
        endesc:"Let's Talk",
        email:"weiyenchang.ca@gmail.com",
        copyright:"2021 WEI-YEN CHANG"
    }

    render(){
        const{endesc,email,copyright}=this.state
        return(
            <div className={style.footer}>
                <h3>{endesc}: {email} </h3>
                <p>&copy;{copyright}</p>
            </div>
        )
    }
}
export default Footer