import React from 'react';

//Component
import Jumbotron from '../Jumbotron/Jumbotron';

//Framer-motion
import {motion} from 'framer-motion';
import {fade} from '../Animation/animation';



const HomePage=()=>{

        return(

            <motion.div initial="out" animate="in" exit="out" variants={fade}>

                <div>
                    <Jumbotron/>
                </div>

            </motion.div>
            
        )
}
export default HomePage;

