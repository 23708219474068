import * as React from "react";
import style from './WeiSvg.module.css';

function SvgComponent(props) {
  return (
    <svg
      id="prefix__\u5716\u5C64_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 805 768"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st1{fill:#033359}.prefix__st2{fill:#e73c2b}.prefix__st4{fill:none;stroke:#e73c2b;stroke-miterlimit:10}"
        }
      </style>
      <path
        className={style.st1}
        d="M364.2 177.3c5.8 22 17.2 44.9 35.8 58.9 17.5 13.2 41 5.4 56.3-7.7 8.5-7.3 14.7-16.9 18.5-27.4.4-1-.3-5.1-.7-4.3-8.6 23.9-36.5 48-63.5 40.1-10.5-3.1-18.7-12.2-25.2-20.5-9.8-12.6-16.5-27.2-20.6-42.6-.5-2.1-.9 2.6-.6 3.5z"
      />
      <path
        className={style.st1}
        d="M364.4 173c-5.2-2.5-9.6-7.2-11.6-12.6-2.7-7.3 1-15.7 6.6-20.7.9-.8-.1-5-.4-4.8-13.1 11.7-10.4 35.4 5.6 43.1 0 0 1-4.4-.2-5zM394.2 87c5.9 9.9 11.4 20.8 19.2 29.5 4.7 5.3 11.1 9.5 18.5 9.4 8.3-.1 15.9-3.9 24.5-3.3 11 .8 20.9 6.1 29.1 13.3.2.2 1.1-4 .2-4.8-7.5-6.5-16.2-11.6-26.1-13.1-9.1-1.4-16.8 1.8-25.7 2.8-8.1.9-15-3.3-20.3-9.1-7.8-8.6-13.2-19.5-19.2-29.5 0 .1-.9 3.6-.2 4.8z"
      />
      <path
        className={style.st1}
        d="M394 85.2c2.2 19.3-11.1 37-29.3 42.5-1.1.3-.3 5.1.1 5 21.3-6.4 32.7-27.3 30.2-48.8-.4-3.5-1.2-.5-1 1.3z"
      />
      <path
        className={style.st1}
        d="M413.4 37.8c-17.7-6.5-35.1-2.1-46.9 12.6-10.1 12.6-16.9 29.6-20.9 45-4.3 16.4-3.6 34.3-10.7 49.9-3.1 6.9-6.8 12.6-7.6 20.3-.8 8.1.5 16.6 4.1 24 6.5 13.5 18.9 22.9 33.5 26.1.6.1 1.4-7.1-.2-7.4-12-2.6-22.7-9.5-29.6-19.7-5.3-8-8.2-17.3-4.4-26.6 2.5-6.1 6-11.5 8-17.8 4.4-14.3 4.3-29.5 8.3-43.9 3.9-14.1 10.1-29.3 18.9-41.1 11.7-15.6 29.3-20.6 47.6-13.8.4-.1 1.7-7-.1-7.6z"
      />
      <path
        className={style.st1}
        d="M413.9 46.3c7.8-5.4 17.5-8.1 27-6.2 10.3 2.1 19.3 9.3 26.9 16.3 13.1 12 23.6 27 31.5 42.9 3.6 7.3 6.5 14.8 9.2 22.5 5.2 14.8 10.1 30.7 10.1 46.5 0 8.2-1.9 16.8-7.4 23.2-7.5 8.6-19.2 10.1-29.9 9.3-1.3-.1-1.6 9.8.2 9.9 10.3.8 21.4-.6 29.1-8.2 5.5-5.4 8.1-13 9.2-20.5 2.8-17.9-.9-36.7-5.8-53.9-7.8-27-19.3-52.9-38.5-73.8-8.4-9.2-18.9-18.9-31-23-10.9-3.6-22.2-.8-31.4 5.5-1.8 1.2-.1 10.1.8 9.5zM439.8 152.3c-.4 7.5-.4 15.1.2 22.6.2 2.7 2.1 8.5-.5 10.3-2.8 1.9-8.5.1-11-1.1-.1 0-1.1 4.4.1 5 4.9 2.2 13.6 3.5 13.7-3.8.1-4.5-1.1-9.1-1.4-13.6-.4-6-.4-12 0-18 0-1.8-.9-4.8-1.1-1.4zM384.5 219.9c0 3.8-.4 7.7-1.3 11.4-.6 2.5-1.4 7.9-4 9.3-1.2.6 0 5 .1 5 3.2-1.8 3.9-7.5 4.7-10.7 1.1-4.9 1.5-9.9 1.5-14.9.1-2.9-1-2.9-1-.1zM454.9 227.1c-1.1 8.9-.3 17.8 2.4 26.4.5 1.5 1-1.1 1.1-1.4.2-1.6.1-3.5-.4-5.1-1.7-5.4-2.2-10.7-1.5-16.3.1-1.5-1-8.2-1.6-3.6zM403.8 174c-10.1-.8-21.8-7.4-23.7-18-1.8-9.7 8.1-20.2 16.5-23 11.1-3.6 22.6 1.8 27 12.6 4.9 12.2-6.8 28.2-19.8 28.4-.8 0-.7 5 .1 5 10.8-.1 19.5-10.6 21.6-20.5 2.2-10.5-1.5-23-11.1-28.7-19-11.3-38.4 9.9-35.2 28.6 2 11.9 13.1 19.6 24.7 20.6.6 0 .8-4.9-.1-5z"
      />
      <path
        className={style.st1}
        d="M470.4 176.4c-10.9 1.8-21.4-6.8-24.2-17.1-3.1-11.3 4.4-23.3 16.4-23.9 9.6-.5 20 6.1 22.6 15.6 2.8 10.2-4.6 22.8-14.8 25.4-1 .3-.3 5.1.1 5 18.2-4.7 21.6-33.2 8.5-44.7-7.9-7-20.7-8.9-28.6-.9-6.2 6.2-7.1 16.3-5.6 24.5 2.1 11.8 12.8 23.3 25.6 21.1 1.1-.2.6-5.1 0-5z"
      />
      <path
        className={style.st1}
        d="M444.6 156.7c-6-1.3-11.9-3.7-18.1-2.1-1 .3-.3 5.1.1 5 6.2-1.6 12.1.8 18.1 2.1.5 0 1-4.8-.1-5zM623.5 373.3c-79.8-1.4-159.5-2.7-239.3-4.1l-67.8-1.2c-.7 0-.7 4.9.1 5 79.8 1.4 159.5 2.7 239.3 4.1l67.8 1.2c.8-.1.8-5-.1-5z"
      />
      <path
        className={style.st1}
        d="M621 377.8c-4.7 48.7-9.3 97.3-14 146-.2 2.5-1 8.1.8 10.2 1.7 1.9 2-.9 2.2-2.7 4.7-48.7 9.3-97.3 14-146 .2-2.5 1-8.1-.8-10.2-1.7-2-2 .8-2.2 2.7zM311.8 388.4c-2.9 47.7-5.7 95.5-8.6 143.2-.1 2.1-.6 11.2 1.8 12.2s2.4-5.8 2.5-7c2.9-47.7 5.7-95.5 8.6-143.2.1-2.1.6-11.2-1.8-12.2s-2.4 5.8-2.5 7zM559.4 553c-55.3.4-110.5-.3-165.8-1-18.1-.2-36.3-.5-54.4-.7-.7 0-.7 4.9.1 5 55 .7 110.1 1.7 165.1 1.9 18.4 0 36.7 0 55.1-.1.8-.1.8-5.1-.1-5.1z"
      />
      <path
        className={style.st1}
        d="M462.5 601.1c-15.1-7.5-31.7-10.8-47.6-16.1-8.4-2.8-16.5-6.2-24-11-6.6-4.3-12.8-8.4-20.3-11.2-17-6.4-34.6-11.4-51.7-17.5-17.6-6.4-35-13.4-51.7-21.8-.1 0-1.1 4.4.1 5 16.8 8.4 34.1 15.4 51.7 21.8 17.1 6.2 34.7 11.1 51.7 17.5 8.4 3.2 15.2 8.3 22.9 12.8 6.7 4 14 6.9 21.5 9.4 15.9 5.3 32.5 8.5 47.6 16.1 0 0 1-4.4-.2-5z"
      />
      <path
        className={style.st1}
        d="M304.6 529.4c-21-23.1-55.9-32.3-85.5-22-13.6 4.8-23.8 14.1-28.5 27.9-4.4 12.7-4.5 26.7-.8 39.6 3.3 11.5 9.3 22.5 17.1 31.7.7.8.9-2.4.9-2.6.1-1.2.2-3.5-.7-4.6-14.8-17.5-24.3-42.1-13-63.8 12.6-24.2 45.5-28.4 69.6-22.7 15.6 3.7 29.8 11.8 40.6 23.7.2.1 1.6-5.7.3-7.2zM398 621.7c1.5-4.6 9.3-5.8 13.3-6.7 10.5-2.3 21.2-3.3 31.8-4.8 21.1-2.9 40.7-8.4 59.8-17.9 18.4-9.1 34.9-21 52.6-31.2 10-5.8 20.5-10.8 31.1-15.4 8.5-3.7 17.3-7.4 26.7-7.9 17.6-.9 37.1 8.5 46.7 23.3 11.6 17.7 3.3 38.2-8.3 53.5-20.5 27.1-58.2 31.3-89.2 37.2-49.1 9.4-97.8 20.5-146.1 33.9.3 1.9.6 3.7.8 5.6.8-8.6 1.1-17.3 1.1-26 .1-6.8.1-13.7-1.3-20.3-1.3-6.4-4.2-12.3-8.6-17.1-1.9-2.1-4.1-4-6.3-5.8l-2.4-2.1c-.3-.3-.7-1-1.1-1.2-1.4-1 .6-3.5-.5 2.3.3-1.4.6-4-.1-5.3-.5-.9-.8-1.7-1.1 0-.7 3.8-.4 7.9 2.2 10.8 3.7 4.2 8.5 7.1 11.9 11.7 4.5 6.3 5.8 13.2 5.9 20.8.1 9.5-.1 19-1 28.4 0 .4-.1 5.8.8 5.6 45.8-12.7 92-23.5 138.7-32.5 20-3.9 40.5-7 59.9-13.7 14.2-4.9 27.2-12.4 36.5-24.4 10-12.9 16.6-28.6 15.8-45.1-.9-20.9-14.9-36.8-34.1-43.8-8.4-3-17.3-4.3-26.1-2.7-10.8 2-21 7.1-30.8 11.7-19.7 9.1-37.1 21.6-55.8 32.5-11.4 6.7-23.3 12.8-35.6 17.6-12.7 4.9-25.8 7.5-39.3 9.5-11.5 1.8-23.2 2.7-34.6 5.2-4.7 1-12.4 2.3-14.1 7.7-.5 1.5-.6 3.6-.2 5.1-.1.6.5 3 1 1.5z"
      />
      <path
        className={style.st1}
        d="M322.5 635.5c-38.9-10.8-55.6-16.1-63.1-18.8-1.2-.4-6.1-2.5-13-4.3-5.2-1.4-10.4-2.7-15.7-4.1-8.9-2.3-18.7-4.6-26.9-.6-6.8 3.3-11.2 10.3-12.9 17.6s-.9 15 .5 22.4c.9 4.9 2 9.8 3.5 14.6 2.4 8.2 5.8 16.3 11.3 22.8 4.2 4.9 9.6 8.7 15.2 12 3.4 2 7 3.8 10.8 4.7 3.3.8 6.6 1 10 1.2 6.3.4 12.6.7 18.8 1.1 5.8.3 10.6.4 15.3.2 1.7-.1 3.3-.1 4.7-.2l4.6-.4c3.4-.3 23.1-4.5 49.9-11.3"
      />
      <path
        className={style.st1}
        d="M216.1 609c1.6 12.8 0 25.8-1.5 38.5-1.3 11-3.4 21.8-8.5 31.6-.6 1.2.5 4.7.4 4.8 5.8-11.1 8-23.3 9.3-35.7 1.5-13.9 2.9-27.9 1.2-41.8-.4-3-1 1.6-.9 2.6z"
      />
      <path
        className={style.st1}
        d="M208.4 604c-13.2-3.5-24.9 1.2-29.7 14.2-3.2 8.5-3.6 18-3 27 .7 11.6 3.7 22.9 9.2 33.2 5.8 10.9 15 22.4 27.7 25 6.5 1.4 13.5.8 20.1.5 9.1-.4 18.1-1.4 27-3 1-.2.5-5.1-.1-5-12.8 2.3-25.9 3.5-38.9 3.2-10.9-.2-19.6-4.6-26.9-12.8-13.8-15.8-21.2-40.7-15.1-61.3 1.5-5.1 4.1-10.1 8.4-13.4 6.2-4.7 14.3-4.6 21.6-2.7.2.2.8-4.6-.3-4.9zM473 679.2c13.3 7 27.3 12.6 41.8 16.7.1 0 .1-.1.2-.1 5.1-9.4 10.2-18.7 15.3-28.1.7-1.3.6-3.2.5-4.6 0-.3-.7-3.3-1.1-2.6-3.2 5.9-6.4 11.7-9.6 17.6-1.3 2.4-2.8 7-4.8 8.8-2.4 2.2-6.7-.1-9.4-1-11.4-3.7-22.5-8.5-33.2-14.1.1-.1-1.5 6.4.3 7.4z"
      />
      <path
        className={style.st1}
        d="M524 680.2c7 4.2 14.1 8.5 21.1 12.7 5.4 3.2 12.1 9.2 18.9 8.4 4.1-.5 7.5-2.9 11.3-4.3 5.9-2.2 12.1-3.2 18.3-4 .9-.1.5-5-.1-5-5.8.7-11.7 1.7-17.3 3.6-3.7 1.3-7.1 3.6-10.9 4.4-7.6 1.6-14.7-4.8-20.9-8.5-6.8-4.1-13.5-8.1-20.3-12.2-.4-.2-1.1 4.3-.1 4.9zM405.1 153.9c-.1 1.2-.4 2.3-1 3.4-.1.2-.3.4-.4.6-.4.1-.4.5-.1 1.1-.2 1.1-.2 1.4.1.9.3-.2.7-.8 1-1.1 1.7-1.7 1.6-4.1 1.2-6.3-.2-.9-.6-.8-.8 0-.3 1.1-.1 2.4.1 3.5 0 .1 0 .2.1.3-.2-.8-.2-1.6-.2-2.4-.1-1.2-.1-.6-.6-.1-.2.2-.5.4-.7.7-.6.6-1 1.3-1.1 2.1-.4 1.9-.4 4.7 1 6.3.1.1.2.1.3 0 1.3-2 2-4.2 2.1-6.5.1-.9.3-2.6-.3-3.4-.6-.7-.7.4-.7.9zM432.9 201.3c-3.6.2-7.2.5-10.7-.2-4.2-.9-7.3-3.4-10.5-6.1-1-.9-1.2 3.1-1.2 3.5-.1 1.4-.3 5 .9 6.1 2.9 2.5 5.9 5 9.7 6.1 3.9 1.1 8.1.9 12.1.6 1.6-.2 1.1-10.1-.3-10zM464.1 156.9c-.1 1.2-.4 2.3-1 3.4-.1.2-.3.4-.4.6-.4.1-.4.5-.1 1.1-.2 1.1-.2 1.4.1.9.3-.2.7-.8 1-1.1 1.7-1.7 1.6-4.1 1.2-6.3-.2-.9-.6-.8-.8 0-.3 1.1-.1 2.4.1 3.5 0 .1 0 .2.1.3-.2-.8-.2-1.6-.2-2.4-.1-1.2-.1-.6-.6-.1-.2.2-.5.4-.7.7-.6.6-1 1.3-1.1 2.1-.4 1.9-.4 4.7 1 6.3.1.1.2.1.3 0 1.3-2 2-4.2 2.1-6.5.1-.9.3-2.6-.3-3.4-.6-.7-.7.4-.7.9zM380.4 140.2c-5.7-2.9-11.7-4.8-18.1-5.6-.9-.1-1.3 7.3.2 7.4 6.4.8 12.4 2.6 18.1 5.6.1 0 1.6-6.5-.2-7.4zM457.7 241.3c1.6 0 4.3-.5 5.8 0 .7.3 1 .2.8-.3-.5 1.4-1.1 2.7-1.8 4-1.5 2.8-3.5 5.3-5.7 7.5-4.1 4.2-8.1 6.3-8 12.7 0 2.6.6 4.9 1.2 7.4 1.1 4.5.8 9 1.3 13.6.1 1 0 2.7 1.4 3 2.3.5 3.1-4.1 4.9-4.6 1-.3.4-5.1-.1-5-1.2.3-4.3 5.3-5.1 4.6-.3-.3-.1-7.1-.2-7.8-.5-4.2-3.4-10-.8-13.9 1.8-2.7 5.3-4.6 7.4-7 3.4-3.9 6.9-9.4 7.2-14.8.4-5.9-3.4-4.4-8.4-4.4-.7 0-.7 5 .1 5z"
      />
      <path
        className={style.st1}
        d="M456.5 270.2c-1.5 9.2 3.2 19.8 13.2 21.8 5.1 1 8.7-9 10.6-12.7 3.3-6.4 6-13 8.1-19.8.3-.9.7-4.1-.4-4.6-2.8-1.4-5.8-2.7-8.4-4.5-2.2-1.5-3.1-3.6-4.4-5.8-2.5-4.1-6.3-6.6-10.8-7.8-.4-.1-1 4.7.1 5 6.4 1.7 8.6 5.5 12.1 10.6 2.5 3.7 7.7 5.6 11.6 7.6-.1-1.5-.3-3.1-.4-4.6-3 9.4-7 18.5-12.2 26.9-.9 1.5-2.3 4.6-4.2 5.1-1.5.4-3.7-.9-4.9-1.6-4-2.1-9.9-6.7-9.1-12 .1-.9-.6-5.6-.9-3.6zM380.1 230.9c-7.7 1.4-14 5.7-18.1 12.4-4.3 7.1-6.7 15.6-14.6 19.6-.9.5-.7 4.5.1 5 3.1 1.6 5.7 3.6 8.1 6.1.1.1 1.1-3.8.2-4.8-2.5-2.6-5.2-4.6-8.4-6.3 0 1.7.1 3.3.1 5 6.9-3.6 9.8-10.5 13.3-17 4.2-7.9 10.4-13.4 19.4-15 1-.2.5-5.1-.1-5z"
      />
      <path
        className={style.st1}
        d="M378.6 241.5c-.4 5.3.7 9.7 4.2 13.8 2.4 2.9 5.6 5 8 7.8 2.3 2.7 3.2 5.1 3.8 8.6.6 3.8.3 6.8-2.9 2.9-1.7-2.1-2.5-4.9-4.9-6.5-3-2-6.7-1.3-10-.4-7.3 2.1-15.4 6.4-22.1.5-.2-.2-1.1 4-.2 4.8 7 6.2 15.5 1.5 23.1-.5 4.7-1.3 8.8-.8 11.7 3.4 1 1.4 3.4 7 5.8 5.5 1.4-.9 1.1-4.7 1.1-5.9.1-3.2-.2-6.5-1.1-9.7-1-3.4-2.8-6.2-5.2-8.7-3-3.2-10.7-8-10.3-13.2.1-1-.7-5.5-1-2.4z"
      />
      <path
        className={style.st1}
        d="M448.4 262.3c-8.2 4.5-16.9 6.6-26.3 7.1-9.2.4-19.4.9-27.6-3.9-.5-.3-1.1 4.2-.2 4.8 7.9 4.6 17.1 4.4 25.9 4.2 10-.3 19.4-2.3 28.2-7.1 1.3-.7.1-5.1 0-5.1zM356.7 251.1c-12.8 4.8-25.5 9.9-38.1 15.3-11.7 5.1-23.9 10.1-34.9 16.7-10.1 6.1-16.7 14.9-21.9 25.4-5.5 11.1-10.2 22.5-14.8 34-4.9 12.3-9.3 24.7-13.3 37.3-3.5 11.1-8.3 23.1-9.1 34.8-.8 11.6 3.8 23.6 6.5 34.8 3.3 13.2 6.4 26.5 9.8 39.6 2.9 11.3 7.4 21 19.8 23.5 14.6 2.9 30.3 2.5 45.1.8 1.3-.1.8-7.6-.2-7.4-12.1 1.3-24.3 1.5-36.5.3-4.9-.5-10.2-.9-14.7-3-5.4-2.5-8.6-7.4-10.6-12.9-4.1-11.1-6.1-23.3-8.9-34.8-1.4-5.7-2.8-11.5-4.2-17.2-1.6-6.7-5.1-14.9-4.5-21.7.5-5.9 2.9-12.1 4.6-17.8 1.7-5.7 3.4-11.4 5.3-17.1 3.7-11.3 7.8-22.6 12.2-33.7 4.3-10.9 8.9-21.8 14.1-32.4 4.9-10 11.1-18.6 20.7-24.6 10.8-6.8 23.1-11.7 34.7-16.8 12.8-5.6 25.8-10.8 38.9-15.7 1.8-.6.5-7.6 0-7.4zM392.5 285.9c-.1-1.6-1.2 2.2-1.5 2.8-.7 1.4-1.5 2.7-2.3 4.1-1.4 2.5-2.9 5-3.8 7.7-2.1 5.9-2.3 12.4-2.3 18.6 0 14.8.3 29.6 3.2 44.3.5 2.7 1.7 2.3 2.3-.1.8-3.3.4-7.2-.3-10.5-1.3-6.4-1.7-13-1.8-19.6-.1-5.7-.9-12.2.3-17.9 1.1-5.1 4.7-9.3 6.9-14 2.3-4.9 2.9-10 2.7-15.4 0-1-.1-7.4-1.8-7.4-1.9-.1-1.6 6.7-1.6 7.4zM488.7 264.5c13.2 5.9 27.4 9.1 40.7 14.6 12.6 5.2 23.6 13.6 33 23.4 17.6 18.3 28.3 43.1 28.8 68.6 0 .5 0 3.7.9 3.7.8 0 .7-3.4.7-3.7-.5-25.6-8.9-50.6-25.4-70.3-9.4-11.2-21.1-21-34.3-27.2-14.3-6.8-30.2-9.9-44.7-16.3-.1-.3-1.5 6.4.3 7.2z"
      />
      <path
        className={style.st1}
        d="M299.6 400.5c.4-9.8-1.6-19.6-4.7-28.9-2.8-8.5-7.9-16.7-17.2-18.8-.6-.1-1.4 7.1.2 7.4 6.9 1.5 11.6 6.7 14.5 12.9 3.5 7.4 6 17.2 5.6 25.4-.1 2.7 1.4 7.2 1.6 2zM472.5 298.8c4.7 17.1 4.8 34.1.6 51.3-.8 3.2-.7 7.4.3 10.5.7 2.5 1.6 2.6 2.3-.1 5.7-23.6 5.3-48.9-1.1-72.3-.7-2.5-1.7-2.4-2.3.1-.8 3.1-.7 7.3.2 10.5z"
      />
      <path
        className={style.st1}
        d="M297.2 402.9c3.6 4.9 7.9 9.1 13 12.4 1.1.7 1.2-3 1.2-3.5 0-1.2.3-5.3-.9-6.1-5.1-3.3-9.4-7.5-13-12.4-.8-1.1-1.2 3.3-1.2 3.5-.1 1.8-.2 4.6.9 6.1z"
      />
      <path
        className={style.st1}
        d="M302.3 399.2c-12.2 9.6-20.8 21.5-30.5 33.4-9.1 11.1-21.3 21.4-36.6 19.3-1.7-.2-2.6 14.5.4 14.9 14.9 2.1 27.3-7.7 36.3-18.5 10.2-12.2 18.9-24.7 31.6-34.7 1.8-1.4 1.1-7.2.9-9.2 0-.9-.5-6.4-2.1-5.2z"
      />
      <path
        className={style.st1}
        d="M297.3 382.6c-20.5.7-31.5 18.1-38.8 35-3 6.9-13 11-20.2 9-3.5-.9-6.4-3.3-8.8-6-.9-1-1.7-2-2.4-3.1-.2-.3-1.4-2.4-.9-1.4-1.4-2.7-2 1.7-2.2 2.7-.3 2.9-.6 7.5.8 10.2 2.3 4.4 5.6 8.4 10 10.9 6.9 3.9 16.6 1.6 22.2-3.8 2.7-2.6 3.7-6.7 5.2-10.1 2.2-5 5-9.8 8.4-14.1 6.8-8.3 16.1-14 27-14.4 2.4-.1 2-15-.3-14.9zM338.6 640c21.9-1.1 43.6-4.3 64.9-9.6 1-.3.4-5.1-.1-5-21.3 5.3-43 8.5-64.9 9.6-.9.1-.7 5 .1 5zM418.3 666.5c-26.3 5.8-52.5 11.7-78.8 17.5-1 .2-.4 5.1.1 5 26.3-5.8 52.5-11.7 78.8-17.5 1-.3.4-5.1-.1-5z"
      />
      <path
        className={style.st1}
        d="M401.4 676.9c4.8 4.3 9.6 8.5 14.4 12.8.2.2 1.1-4 .2-4.8-4.8-4.3-9.6-8.5-14.4-12.8-.2-.2-1.1 4-.2 4.8zM522.1 325.2c2.2 16.6 5.5 33 9.8 49.2.7 2.6 1.7 2.4 2.3-.1.8-3.2.6-7.4-.3-10.5-4-15.2-7.1-30.6-9.2-46.2-.2-1.8-.7-4.6-2.3-2.6s-.6 8-.3 10.2zM330 633.5c4.2-1.4 5-.4 8.9-1.4.4-.1 1.5-.4 2.7 0 .7.3 1.3.9 1.8 1.5 4.1 5 4.4 12 4.4 18.4.1 13.6-.4 24.9-3.7 43.3-.3 1.8-1.1 5.9-2.7 6.1-2 .2-2.2-6-8-12-2.3-2.3-4.9-4.1-8.3-8.5-1-1.2-2.7-3.5-2-5.3.4-.9 1.2-1.6 1.7-2.4.6-1 .6-2.3.7-3.5.4-14.1-3.8-21.7-3.8-21.7-1.4-2.8-2.3-3.9-2.5-5.1v-1c.5-3.5 6.2-6.9 10.8-8.4zM295.3 686.7c.6-15.4 7.8-29.6 14.3-43.2.9-1.8.6-4.9.2-6.8-.2-.8-.6-3.6-1.5-1.7-7.6 15.7-14.5 31.5-15.2 49.2 0 .9-.2 5.8.9 6.1 1.3.2 1.3-2.8 1.3-3.6z"
      />
      <path
        className={style.st1}
        d="M309.2 682.4c-5.9-18.6-10.5-38.1-25.8-51.3-1-.9-1.2 3.1-1.2 3.5-.1 1.4-.3 5 .9 6.1 15 13 19.5 32.1 25.3 50.4.7 2.1 1.4-1.4 1.4-1.8.2-2.3 0-4.7-.6-6.9zM567.1 656.8c5.4 9 9 19.1 10.5 29.6.5 3.5 1.2 7.6 4.5 8.9 1.3.5 2.7.5 4.1.4 27.9-.9 56 .9 83.6 5.4 2.8.5 5.7.9 8.5.2 2.7-.7 4.9-2.5 7-4.3 5.7-4.7 11.5-9.5 15.9-15.4 8.4-11.5 10.9-26.6 8.4-40.6-2.1-11.5-7.7-22.8-17.1-29.8-9.4-7-23-8.8-33.1-2.7-3.5 2.1-6.5 5.1-9.6 7.7-9.4 8-20.7 13.8-32.7 16.7"
      />
      <path
        className={style.st1}
        d="M586.5 661.1c6.5-2.5 12.3.5 17.9 3.8-.2-.9-.4-1.7-.6-2.6.3 5.3 1.1 10.6 2.6 15.7.6 2.1 2 4.4 2.2 6.5.5 1.1.4 1.1-.2 0-.3.3-.5.6-.8 1-.7.6-1 1.8-1.2 2.5-.4 1.5-.6 3.5-.2 5.1.1.4.7 2.9 1.1 1.3.6-2 2.4-3.2 3.1-5.3.5-1.5.5-3.4.4-5-.3-5.1-3.2-9.9-4.3-14.9-.9-4 .3-10.1-3.4-12.5-5.1-3.2-10.9-5.4-16.8-3.1-1.7.7-.2 7.6.2 7.5zM619.6 644c-.4 6.7-3.5 13.4-4.5 20.1-1 6.9-1.3 13.8-.9 20.7 0 .5 0 3.7.9 3.7.8 0 .7-3.4.7-3.7-.4-7.6.2-15.2 1.9-22.6 1.3-5.5 3.1-10.5 3.5-16.2.2-2.7-1.3-7.2-1.6-2z"
      />
      <path
        className={style.st1}
        d="M637.7 681.8c-8.2-14.3-21.9-23.8-33.7-34.9-.3-.3-1.7 5.9-.3 7.2 11.8 11.1 25.6 20.5 33.7 34.9.5.9.9-2.5.9-2.6.1-1.5.1-3.3-.6-4.6z"
      />
      <g className={style.ae_playbar}>
        <path
          className={style.st2}
          d="M208.5 70v158c0 1.9 3 1.9 3 0V89.3 70c0-1.9-3-1.9-3 0z"
        />
        <path className={style.st2} d="M209.8 80.6l11.5-20h-23.1z" />
        <path
          className={style.st2}
          d="M211 81.3c3.8-6.7 7.7-13.3 11.5-20 .6-1-.2-2.3-1.3-2.3h-23.1c-1.1 0-1.9 1.3-1.3 2.3 3.8 6.7 7.7 13.3 11.5 20 1 1.7 3.6.2 2.6-1.5-3.8-6.7-7.7-13.3-11.5-20-.4.8-.9 1.5-1.3 2.3h23.1c-.4-.8-.9-1.5-1.3-2.3-3.8 6.7-7.7 13.3-11.5 20-.9 1.7 1.7 3.2 2.6 1.5z"
        />
      </g>
      <g id="prefix__keyframe_x5F_video_x5F_down">
        <path
          className={style.st1}
          d="M160.8 194.5h-41.3c-14.9 0-30 .6-44.9 0-5.5-.2-10.8-3.4-12.1-9.1-1.3-5.9 1-12.2 6.7-14.8 4.3-2 10.4-1.1 15-1.1h57c6.4 0 12.8-.2 19.2 0 16 .6 16.4 24.1.4 25-.5 0-.3 5 0 5 12.6-.7 14-14.5 12.2-24.4-1.7-9.5-9.9-10.6-17.7-10.6H93.8c-6.4 0-12.8-.3-19.2 0-12.1.5-13.1 11.4-12.8 20.9.2 6.1 2.9 11.9 9.3 13.6 2.1.6 4.3.5 6.4.5h83.3c.4 0 .4-5 0-5z"
        />
      </g>
      <g id="prefix__keyframe_x5F_video_x5F_top">
        <path
          className={style.st1}
          d="M212.8 153.5h-41.3c-14.9 0-30 .6-44.9 0-5.5-.2-10.8-3.4-12.1-9.1-1.3-5.9 1-12.2 6.7-14.8 4.3-2 10.4-1.1 15-1.1h57c6.4 0 12.8-.2 19.2 0 16 .6 16.4 24.1.4 25-.5 0-.3 5 0 5 12.6-.7 14-14.5 12.2-24.4-1.7-9.5-9.9-10.6-17.7-10.6h-61.5c-6.4 0-12.8-.3-19.2 0-12.1.5-13.1 11.4-12.8 20.9.2 6.1 2.9 11.9 9.3 13.6 2.1.6 4.3.5 6.4.5h83.3c.4 0 .4-5 0-5z"
        />
      </g>
      <g id="prefix__browser">
        <path
          className={style.st1}
          d="M711.5 240h-129c.4.8.7 1.7 1.1 2.5v-81.3-11.7c-.4.8-.7 1.7-1.1 2.5h129c-.4-.8-.7-1.7-1.1-2.5v93c0 3 2.2 3 2.2 0v-81.3-11.7c0-.6-.1-2.5-1.1-2.5h-129c-1 0-1.1 1.9-1.1 2.5v93c0 .6.1 2.5 1.1 2.5h129c1.5 0 1.6-5 0-5z"
        />
        <circle className={style.st1} cx={684.5} cy={161.5} r={3.5} />
        <circle className={style.st1} cx={699.5} cy={161.5} r={3.5} />
      </g>
      <path
        className={style.wireframe_leftdown}
        d="M650 169h53v23h-53z"
      />
      <path
        className={style.wireframe_lefttop}
        opacity={0.66}
        fill="#e73c2b"
        d="M649 202h54v31h-54z"
      />
      <g className={style.wireframe_right}>
        <path
          className={style.st2}
          d="M643 230.5h-52c.2.8.4 1.7.5 2.5v-64c-.2.8-.4 1.7-.5 2.5h52c-.2-.8-.4-1.7-.5-2.5v64c0 2.8 1.1 2.9 1.1 0v-64c0-.3 0-2.5-.5-2.5h-52c-.6 0-.5 2.3-.5 2.5v64c0 .3 0 2.5.5 2.5h52c.7 0 .7-5-.1-5z"
        />
        <path className="prefix__st4" d="M591 169l52 64M591.5 233.5l52-64" />
      </g>
      <g className={style.coding_5} opacity={0.49}>
        <path
          className={style.st2}
          d="M166.1 303.8h-52c-6.4 0-6.4 10 0 10h52c6.4 0 6.4-10 0-10z"
        />
      </g>
      <g className={style.coding_4}>
        <path
          className={style.st2}
          d="M185.2 312.7h19.1c6.4 0 6.4-10 0-10h-19.1c-6.5 0-6.5 10 0 10z"
        />
      </g>
      <g className={style.coding_3}>
        <path
          className={style.st2}
          d="M200 319.7h-85.9c-6.4 0-6.4 10 0 10H200c6.4 0 6.4-10 0-10z"
        />
      </g>
      <g className={style.coding_2} opacity={0.45}>
        <path
          className={style.st2}
          d="M205.3 337.3l-79.6-.9c-6.4-.1-6.4 9.9 0 10l79.6.9c6.5 0 6.5-10 0-10z"
        />
      </g>
      <g className={style.coding_1}>
        <path
          className={style.st2}
          d="M205.6 353.3h-63.5c-6.4 0-6.4 10 0 10h63.5c6.4 0 6.5-10 0-10z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
