import React from 'react';
import style from './ScrollIndicator.module.css'
import { BsArrowRight } from "react-icons/bs";


const ScrollIndicator=()=>{

        return(
            <div className={style.scroll_indicator}>

                <div>SCOLL DOWN <BsArrowRight/></div>

            </div>
        )

}

export default ScrollIndicator;