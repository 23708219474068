import React,{Component} from 'react';

//Router
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

//style-component
import './App.css';

//Web Comonent
import HomePage from './Page/HomePage';
import WorkPage from './Page/WorkPage';

//Framer-motion
import {AnimatePresence} from 'framer-motion';


class App extends Component{


  render(){
    return(
      <>
      <Router>
          <div>
            <AnimatePresence exitBeforeEnter>
            <Switch>
                <Route path="/" exact component={HomePage}/>
                <Route path="/:id" component={WorkPage}/>
            </Switch>

            </AnimatePresence>
          </div>
      </Router>
      </>
    )
  }
}

export default App