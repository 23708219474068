import React,{Component} from 'react';
import Portfolio from '../Data/Portfolio.json'
import ImageRow from './ImageRow';
import style from './Thumbnail.module.css';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {fade} from '../Animation/animation';
import ScrollIndicator from '../ScollIndicator/ScrollIndicator';

/**
 *<div className={style.desc}>
    <div className={style.language}>{works.lag}</div>                                  
        <ToolsStyle>
            {works.tools.map(

                (tools,index)=>(

                    <div key={`${index}_${tools.name}`}>

                        <div>{tools.name}</div>

                    </div>
                                                
                )
            )} 

        </ToolsStyle>

    </div>
 * 
 */


class Thumbnail extends Component{

    state = {
        currentType:"Portfolio",
    }


    onChange=(e)=>{
        this.setState(
            {
                currentType:e.target.value,
            }
        )
    }

    render(){

        const{currentType}=this.state;
        let showType;

        
        if (currentType==="Portfolio"){

            showType=Portfolio

        }else{

            showType=Portfolio.filter(
                Portfolio=>Portfolio.type===currentType
            )
        }

        return(
            <motion.div initial="out" animate="in" exit="out" variants={fade}>

                <ScrollIndicator/>

                <div id="portfolio" className={style.thumbnail_section}>

                    <div className={style.title}>

                        <h1>{currentType}</h1>

                        <div className={style.filter}>
                            <button type="submit" value="Portfolio" onClick={this.onChange}>All</button>
                            <button type="submit" value="UIUX" onClick={this.onChange}>UIUX</button>
                            <button type="submit" value="Motion" onClick={this.onChange}>Motion</button>
                            <button type="submit" value="Graphic" onClick={this.onChange}>Grphic</button>
                            <button type="submit" value="Others" onClick={this.onChange}>Others</button>
                        </div>

                    </div>

                    <ImageRow>

                        {showType.map(

                            (works)=>
                            (   
                                <div key={works.id} className={style.thumbnail}>  

                                    <Link to={`/${works.id}`} target="_self">

                                        <div className={style.thumbnail_image}>

                                            <img src={`/img/${works.image}`} alt={works.title}/>

                                        </div>

                                    </Link>

                                    <h4>{works.entitle}</h4>
                                    <p>{works.zhtitle}</p>
                                    
                                    <div className={style.desc}>
                                        <div className={style.language}>{works.lag}<br/>{works.sw}</div> 
                                    </div>

                                </div>
                            )
                        )}

                    </ImageRow>

                </div>
                
            </motion.div>
        )
    }
}
export default Thumbnail;