import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import style from './WorkPageMenu.module.css'

class WorkPageMenu extends Component{
    render(){
        const {id,zhtitle,entitle,image}=this.props;
        return(


                <Link to={`/${id}`} targert="_self">

                    <div key={`wp_menu_item_${id}`} className={style.wp_menu_item}>

                        <div className={style.menu_content}>

                            <div className={style.menu_img}>

                                <img src={`/img/${image}`} alt={zhtitle}/>

                            </div>

                            <div className={style.menu_text}>
                                <h3>{entitle}</h3>
                                <h3>{zhtitle}</h3>
                            </div>

                        </div>

                    </div>

                </Link>

        )
    }
}
export default WorkPageMenu