import React,{Component} from 'react';
import style from './Jumbotron.module.css';
import Button from './Button';
import {HashLink as Link} from 'react-router-hash-link';
import WeiSvg from './WeiSvg';
import Thumbnail from '../Thumbnail/Thumbnail';
import ScollIndicator from '../ScollIndicator/ScrollIndicator';
import Navbar from '../Navbar/Navbar';
import SocialMedia from '../SocialMedia/SocialMedia';
import Footer from '../Footer/Footer';



class Jumbotron extends Component{

    state={
        'myname':"Hi I'm Wei",
        'job_title':'Multimedia & eLearning Designer',
        'features':'A goal-orientated, self-taught, and a lifelong learner.',
        'en_desc':"I started my career in training field, then finding out the passion in visual communication, now I combine multimedia tools and instructional design to create engaging learning experiences for learners. ",
        'zh_desc':"",
        'cta':"CHECK MY WORKS",
        'lets_talk':"Let's Talk :",
        'email':'weiyenchang.ca@gmail.com',
        'copyright':"Copyright 2021-2022 WEI-YEN CHANG",
    }

    render(){
        const{myname,job_title,en_desc,zh_desc,lets_talk,email,copyright,cta}=this.state;

        return(
            <div id="home" className={style.hero}>
                <div className={style.content}>
                    <div className={style.content_align}>
                        <Navbar/>
                        <div className={style.short_intro}>
                            <h1>{myname}</h1>
                            <h2>{job_title}</h2>
                            <p>{en_desc}</p>
                            <p>{zh_desc}</p>
                            
                            <Link smooth to="/#portfolio_app">
                                <Button>{cta}</Button>
                            </Link>
                        </div>


                        <div className={style.wyn_animation}>
                            <WeiSvg/>
                        </div>

                        <div className={style.closing}>
                            <div className={style.content_align}>
                                <h2>{lets_talk}</h2>
                                <Link  to={{pathname:'mailto:weiyenchang.ca@gmail.com'}} target="_blank">
                                    <h2>{email}</h2>
                                </Link>
                                <SocialMedia/>
                                <h3>&copy;{copyright}</h3>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={style.portfolio_web}>
                    <Thumbnail/>
                    <ScollIndicator/>
                </div>
                <div id="portfolio_app" className={style.portfolio_app}>
                    <Thumbnail/>
                </div>
                <div className={style.footer_app}>
                    <Footer/>
                </div>
            </div>
        )
    }
}
export default Jumbotron;